import { Box, HStack, Text, VStack, Image, Grid, GridItem} from "@chakra-ui/react";
import React from "react";
import GalleryBlock from "./GalleryBlock";
import "./fonts/fonts.css";
import Title from "./Title";

function Home(){
    const categories = ["Arts and Crafts", "Team Sports", "Leadership", "Cooking", "Formal", "Dance", "Film"];
    const title = ["Memories to Look Back On"];
    const subtext = ["(02) Moments"];
    const Images = [
        "https://cdn.testing-cylc-dfw.online/Art-1.jpg",
        "https://cdn.testing-cylc-dfw.online/Sports-5.jpg",
        "https://cdn.testing-cylc-dfw.online/Leadership-1.jpg",
        "https://cdn.testing-cylc-dfw.online/Cooking-2.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-1.jpg",
        "https://cdn.testing-cylc-dfw.online/Dance-1.jpg",
        "https://cdn.testing-cylc-dfw.online/Shipwreck-23.jpg",

    ];
    const titleLineOne = ["We are:"];
    const titleLineTwo = ["Future Leaders"];

    return(
        <Box>
            <Box
            bg={'#00cecb'}
            maxWidth={'100%'}
            height={'100%'}
            >
                <Title
                titleLineOne={titleLineOne}
                titleLineTwo={titleLineTwo}
                />
            </Box>
            <Image
            src="https://cdn.testing-cylc-dfw.online/Leadership-2.jpg"
            width={"100%"}
            height={{base:"35vh",lg:"65vh"}}
            content="center"
            objectFit={"cover"}
            />
            <Box
            height={"100%"}
            width={"100%"}
            bg={"#FFFFEA"}
            alignItems={"center"}
            justifyContent={"center"}
            padding={{base: '5%', lg: '5%', xl: "5%" }}
            >
                <VStack
                padding={"5%"}
                alignItems={"left"}
                >
                    <Grid
                    templateColumns='repeat(5, 1fr)'
                    gap={"10vw"}
                    >
                        <GridItem
                        colSpan={1}
                        align={"left"}
                        fontSize={{base:"4vw", lg:"2.5vw"}}
                        whiteSpace={"nowrap"}
                        fontFamily={'NohemiLight'}
                        >
                            Our Mission:
                        </GridItem>
                        <GridItem
                        colStart={2}
                        colEnd={6}
                        align={"right"}
                        fontFamily={'NohemiLight'}
                        fontSize={{base:"3vw", lg:"1.5vw", "2xl":"1.25vw"}}
                        >
                            (01) Mission
                        </GridItem>
                    </Grid>
                    <HStack
                    marginBottom={"3%"}
                    >
                        <Text
                        maxWidth={"50%"}
                        fontSize={{base:"3.25vw", lg:"2.5vw"}}
                        color={"#FF716E"}
                        fontFamily={'Nohemi'}
                        >
                            To provide direction, leadership, and guidance while building confidence through
                            enhancing communication and team building skills in aspiring youth.
                        </Text>
                        <Image
                        maxWidth={"50%"}
                        src="https://cdn.testing-cylc-dfw.online/CYLC2023logo.jpg"
                        boxSize={"30%"}
                        borderRadius={{base:"10px", lg:"20px"}}
                        marginLeft={"20%"}
                        >
                        </Image>
                    </HStack>
                </VStack>
                <Box
                justifyContent={"center"}
                display={"flex"}
                p={"5%"}
                pb={"10%"}
                >
                    <GalleryBlock
                    categories={categories}
                    images={Images}
                    title={title}
                    subtext={subtext}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default Home;