import { ChakraProvider } from '@chakra-ui/react';
import Header from './Components/Header';
import {BrowserRouter  as Router, Route, Routes} from "react-router-dom";
import Home from './Components/Home';
import Details from './Components/Details';
import About from './Components/About';
import Footer from './Components/Footer';
import Application from './Components/Application';
import './App.css';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Header/>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/details" element={<Details/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/application" element={<Application/>}/>
          </Routes>
        <Footer/>
      </Router>
    </ChakraProvider>
  );
}

export default App;
