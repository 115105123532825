import React, { useEffect, useRef } from 'react';
import { Box, Text, Heading, Image } from '@chakra-ui/react';
import "./fonts/fonts.css";

const LeadershipBlock = ({ images, speed = 40, categories }) => {
  const galleryRef = useRef(null);

  useEffect(() => {
    const gallery = galleryRef.current;
    let scrollPosition = 0;

    const step = () => {
      if (gallery) {
        scrollPosition += speed / 50;

        // Reset scroll position to seamlessly loop to the beginning
        if (scrollPosition >= gallery.scrollWidth / 2) {
          scrollPosition = 0;
        }

        gallery.scrollLeft = scrollPosition;
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);

    // Cleanup to stop the animation when component unmounts
    return () => cancelAnimationFrame(step);
  }, [images, speed]);

  return (
    <Box
    bg="#ffbe5a"
    width="100%"
    py="3%"> {/* Removed padding to allow full-width gallery */}
      <Text
        textAlign="right"
        fontFamily="NohemiLight"
        fontSize={{ base: "3vw", lg: "1.5vw", "2xl": "1.3vw" }}
        mb="2%"
        pr="5%" // Padding-right to align text without constraining gallery
      >
        (07) Team Sports
      </Text>
      <Heading
        textAlign="left"
        fontFamily="MeshedDisplay"
        fontSize={{ base: "6vw", lg: "5vw", "2xl": "4vw" }}
        maxW={{ base: "60%", lg: "50%" }}
        mb="4%"
        pl="5%" // Padding-left to align heading without constraining gallery
      >
        Work Hard, Play Hard
      </Heading>

      <Box
        ref={galleryRef}
        display="flex"
        overflowX="hidden"
        whiteSpace="nowrap"
        width="100%" // Full width of the red container
        mx="auto" // Center horizontally if needed
        pb="2%"
      >
        {/* Render the images twice to create the seamless scroll effect */}
        {[...images, ...images].map((image, index) => (
          <Image
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            height={{ base: "20vh", lg: "45vh" }}
            width={{base: "60%", lg: "40%", "2xl":"40%"}} // Images take up full width of each item in the flex container
            maxWidth="none" // Prevents max-width from constraining the image size
            borderRadius={{ base: "10px", lg: "20px" }}
            objectFit="cover"
            flexShrink={0}
            mr={"2%"} // Spacing between images
            loading="lazy"
          />
        ))}
      </Box>

      <Text
        textAlign="center"
        fontSize={{ base: "3.5vw", lg: "1.5vw", "2xl": "1.3vw" }}
        fontFamily="NohemiLight"
        color="white"
        mt={"2%"}
      >
        Staying active and healthy is integral to success
      </Text>
    </Box>
  );
};

export default LeadershipBlock;
