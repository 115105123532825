
function About(){
    return(
        <div>
            <h1>About</h1>
            <p>Learn more about our organization and our mission.</p>
        </div>
    )
}

export default About;