import React from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';
import "./fonts/fonts.css";

function Title({ titleLineOne, titleLineTwo }) {
  return (
    <Box position="relative" w="100%" h="100%" overflow="hidden">
      <Heading
        position="absolute"
        zIndex={0}
        fontSize={{ base: "34vw", xl: "34vw", '2xl': "28vw" }}
        opacity="20%"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        whiteSpace="nowrap"
        fontFamily="LemonMilk"
        textAlign="center"
      >
        CYLC
      </Heading>
      
      <VStack
        align="flex-start"
        pt="5%"
        pl={{ base: "5%", xl: "7%", '2xl': "9%" }}
        pr="5%"
        h="100%"
        justify="center"
        spacing={0}
      >
        {titleLineOne.map((line, index) => (
          <Heading
            key={index}
            zIndex={1}
            fontSize={{ base: "9vw", xl: "8vw", '2xl': "7vw" }}
            fontFamily="Nohemi"
          >
            {line}
          </Heading>
        ))}
        {titleLineTwo.map((line, index) => (
          <Heading
            key={index}
            zIndex={1}
            fontSize={{ base: "9vw", xl: "8vw", '2xl': "7vw" }}
            color="#FFED68"
            pb="7%"
            fontFamily="Nohemi"
          >
            {line}
          </Heading>
        ))}
      </VStack>
    </Box>
  );
}

export default Title;
