import React from 'react';
import { Box, VStack, HStack, Image, Heading, Text, AspectRatio, Button } from '@chakra-ui/react';
import "./fonts/fonts.css";

const FilmBlock = () => {
  const openOfficial = () => {
    window.open('https://www.youtube.com/@CYLCOfficial', '_blank', 'noopener,noreferrer');
  };

  const openStudios = () => {
    window.open('https://www.youtube.com/@CYLCStudios', '_blank', 'noopener,noreferrer');
  };

  return (
    <Box bg="#C0B9DD" overflow="hidden">
      <HStack spacing={0} height={{ base: "30vh", lg: "100vh", "2xl": "100vh" }} align="stretch">

        {/* Left Half with Image */}
        <Box width="50%">
          <Image
            src="https://cdn.testing-cylc-dfw.online/Shipwreck-23.jpg"
            height="100%"
            objectFit="cover"
            objectPosition="top"
          />
        </Box>

        {/* Right Half with Text and Video */}
        <VStack width="50%" spacing={0} align="center" justify="flex-start">
          <Box width="100%" pb="5%">
            <HStack position="relative">
              <Heading
                fontFamily="LemonMilk"
                fontSize={{ base: "6vw", lg: "12vh", "2xl": "11vh" }}
                sx={{
                  WebkitTextStroke: '0.2vw white',
                  color: 'transparent',
                }}
              >
                Lights, Camera,
              </Heading>
              <Text
                fontFamily="NohemiLight"
                fontSize={{ base: "3vw", lg: "1.5vw", "2xl": "1.3vw" }}
                position="absolute"
                top="5%"
                right="3%"
                p="2%"
              >
                (03) Film
              </Text>
            </HStack>

            <Heading
              fontFamily="LemonMilk"
              fontSize={{ base: "6vw", lg: "12vh", "2xl": "11vh" }}
              textAlign="right"
              sx={{
                WebkitTextStroke: '0.2vw black',
                color: 'transparent',
              }}
            >
              Action!
            </Heading>
          </Box>

          <AspectRatio ratio={1} width="90%" height={{ lg: "35%", "2xl": "40%" }} mt={{ base: "-9%", lg: "-8%", "2xl": "-7.5%" }}>
            <iframe
              src="https://www.youtube.com/embed/fwqN9MDuHzA?si=DWSVw20e8tIRdSNK&rel=0&modestbranding=1"
              title="YouTube video player"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </AspectRatio>

          <Text fontFamily="MeshedDisplay" fontSize={{ base: "1vh", lg: "4vh", "2xl": "3vh" }} pt="2%">
            Capturing the moments that matter most.
          </Text>

          <HStack spacing={{ base: "10%", lg: "20%" }} px="5%" pt="2%">
            <Button
              borderRadius="50px"
              p="10%"
              width={{ base: "16vw", lg: "15vw", "2xl": "12vw" }}
              onClick={openOfficial}
              fontSize={{ base: "0.75vh", lg: "2vh", "2xl": "2vh" }}
              fontFamily="NohemiLight"
            >
              CYLC Official
            </Button>
            <Button
              borderRadius="50px"
              p="10%"
              width={{ base: "16vw", lg: "15vw", "2xl": "12vw" }}
              onClick={openStudios}
              fontSize={{ base: "0.75vh", lg: "2vh", "2xl": "2vh" }}
              fontFamily="NohemiLight"
            >
              CYLC Studios
            </Button>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default FilmBlock;
