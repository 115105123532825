import React, { useEffect, useRef } from 'react';
import { Box, Text, Heading, Image } from '@chakra-ui/react';
import "./fonts/fonts.css";

const VerticalScrollingGalleries = ({ images1, images2, speed = 30, categories }) => {
  const galleryRef1 = useRef(null);
  const galleryRef2 = useRef(null);

  useEffect(() => {
    const gallery1 = galleryRef1.current;
    const gallery2 = galleryRef2.current;
    let scrollPosition1 = 0;
    let scrollPosition2 = 0;
    let lastTime = performance.now();

    function step(currentTime) {
      const deltaTime = (currentTime - lastTime) / 1000;
      lastTime = currentTime;
      const adjustedSpeed = speed / (window.innerHeight / 800); // Adjust speed based on screen height
      const distance = adjustedSpeed * deltaTime;

      scrollPosition1 += distance;
      scrollPosition2 -= distance;

      if (scrollPosition1 >= gallery1.scrollHeight / 2) {
        scrollPosition1 = 0; // Reset position for seamless scrolling
      }

      if (scrollPosition2 <= 0) {
        scrollPosition2 = gallery2.scrollHeight / 2; // Reset position for seamless scrolling
      }

      if (gallery1) gallery1.scrollTop = scrollPosition1;
      if (gallery2) gallery2.scrollTop = scrollPosition2;

      requestAnimationFrame(step);
    }

    if (gallery1 && gallery2) requestAnimationFrame(step);
  }, [images1, images2, speed]);

  return (
    <Box bg="#392F5A" display="flex" flexDirection="row">
      <Box width={{ base: "60%", lg: "60%" }} display="flex" justify="space-between" pl="5%">

        {/* First Vertical Gallery */}
        <Box ref={galleryRef1} overflowY="hidden" width="48%" height={{ base: "40vh", lg: "90vh" }}>
          <Box display="flex" flexDirection="column">
            {images1.concat(images1).map((image, index) => (
              <Image
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                height={{ base: "20vw", lg: "30vh" }}
                width={{ base: "50vh", lg: "45vw" }}
                borderRadius={{ base: "10px", lg: "20px" }}
                objectFit="cover"
                mb="3%"
                loading="lazy"
              />
            ))}
          </Box>
        </Box>

        {/* Second Vertical Gallery */}
        <Box ref={galleryRef2} overflowY="hidden" width="48%" height={{ base: "40vh", lg: "90vh" }} ml={"3%"}>
          <Box display="flex" flexDirection="column">
            {images2.concat(images2).map((image, index) => (
              <Image
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                height={{ base: "20vw", lg: "30vh" }}
                width={{ base: "30vh", lg: "45vw" }}
                borderRadius={{ base: "10px", lg: "20px" }}
                objectFit="cover"
                mb="3%"
                loading="lazy"
              />
            ))}
          </Box>
        </Box>
      </Box>

      <Box width={{ base: "40%", lg: "40%" }} pl="5%" alignItems="flex-start" justifyContent="center">
        <Text
          textAlign="right"
          fontFamily="NohemiLight"
          fontSize={{ base: "3vw", lg: "1.5vw", "2xl": "1.3vw" }}
          mb="2%"
          p="10%"
          color="gray.200"
        >
          (05) Formal
        </Text>
        <Heading
          textAlign="left"
          fontFamily="MeshedDisplay"
          fontSize={{ base: "5vw", lg: "5vw", "2xl": "4vw" }}
          maxW={{ base: "90%", lg: "80%" }}
          mb="2%"
          color="white"
        >
          A Night You Won't Forget
        </Heading>
        <Text
          w="50%"
          color="gray.200"
          fontFamily="NohemiLight"
          fontSize={{ base: "2.5vw", lg: "1.5vw", "2xl": "1vw" }}
        >
          A magical night of formal attire, dancing, and memories that will last a lifetime.
        </Text>
        <Box
          color="white"
          pt={{ base: "50%", lg: "30%", "2xl": "30%" }}
          fontSize={{ base: "1.5vw", lg: "1.5vw", "2xl": "1.5vw" }}
        >
          _________
        </Box>
      </Box>
    </Box>
  );
};

export default VerticalScrollingGalleries;
