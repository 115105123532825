import {Box} from "@chakra-ui/react";
import React from "react";
import GalleryBlock from "./GalleryBlock";
import AutoScrollingGallery from "./AutoScrollingGallery";
import "./fonts/fonts.css";
import Title from "./Title";
import LeadershipBlock from "./LeadershipBlock";
import FilmBlock from "./FilmBlock";
import CenterGalleryBlock from "./CenterGalleryBlock";
import VerticalScrollingGallery from "./VerticalScrollingGallery";
import SportsBlock from "./SportsBlock";

function Details () {
    const categories = ["Arts and Crafts", "Team Sports", "Leadership", "Cooking", "Formal", "Dance", "Film"];
    const titleDance = ["Dance Your Heart Out"];
    const subtextDance = ["(02) Dance"];
    const titleCooking = ["A Recipe for Success"];
    const subtextCooking = ["(04) Cooking"];
    const titleArt = ["Express Yourself Vividly"];
    const subtextArt = ["(06) Arts and Crafts"];
    const AutoImages = [
        "https://cdn.testing-cylc-dfw.online/Art-2.jpg",
        "https://cdn.testing-cylc-dfw.online/Cooking-4.jpg",
        "https://cdn.testing-cylc-dfw.online/NightActivity-4.jpg",
        "https://cdn.testing-cylc-dfw.online/Formal-2.jpg",
        "https://cdn.testing-cylc-dfw.online/NightActivity-1.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-2.jpg",
        "https://cdn.testing-cylc-dfw.online/Leadership-5.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-4.jpg",

    ];
    const AutoImages2 = [
        "https://cdn.testing-cylc-dfw.online/Art-3.jpg",
        "https://cdn.testing-cylc-dfw.online/Formal-1.jpg",
        "https://cdn.testing-cylc-dfw.online/Sports-2.jpg",
        "https://cdn.testing-cylc-dfw.online/NightActivity-2.jpg",
        "https://cdn.testing-cylc-dfw.online/NightActivity-3.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-3.jpg",
        "https://cdn.testing-cylc-dfw.online/Leadership-6.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-5.jpg",
    ];
    const titleLineOne = ["Camp Details"];
    const titleLineTwo = ["and Program"];
    const DanceImages = [
        "https://cdn.testing-cylc-dfw.online/Dance-1.jpg",
        "https://cdn.testing-cylc-dfw.online/Dance-2.jpg",
        "https://cdn.testing-cylc-dfw.online/Dance-3.jpg",
        "https://cdn.testing-cylc-dfw.online/Dance-4.jpg",
        "https://cdn.testing-cylc-dfw.online/Dance-5.jpg",
        "https://cdn.testing-cylc-dfw.online/Dance-6.jpg",
        "https://cdn.testing-cylc-dfw.online/Dance-7.jpg",
    ];
    const LeaderShipImages = [
        "https://cdn.testing-cylc-dfw.online/Leadership-3.jpg",
        "https://cdn.testing-cylc-dfw.online/Leadership-2.jpg",
        "https://cdn.testing-cylc-dfw.online/Leadership-4.jpg",
        "https://cdn.testing-cylc-dfw.online/Leadership-5.jpg",
        "https://cdn.testing-cylc-dfw.online/Leadership-6.jpg",
        "https://cdn.testing-cylc-dfw.online/Leadership-1.jpg",
    ];
    const CookingImages = [
        "https://cdn.testing-cylc-dfw.online/Cooking-2.jpg",
        "https://cdn.testing-cylc-dfw.online/Cooking-3.jpg",
        "https://cdn.testing-cylc-dfw.online/Cooking-4.jpg",
    ];
    const FormalImages1 = [
        "https://cdn.testing-cylc-dfw.online/Formal-1.jpg",
        "https://cdn.testing-cylc-dfw.online/Formal-2.jpg",
        "https://cdn.testing-cylc-dfw.online/Formal-3.jpg",
        "https://cdn.testing-cylc-dfw.online/Formal-4.jpg",
        "https://cdn.testing-cylc-dfw.online/Formal-6.jpg",
        "https://cdn.testing-cylc-dfw.online/Formal-7.jpg",
        "https://cdn.testing-cylc-dfw.online/Formal-5.jpg",
    ];
    const FormalImages2 = [
        "https://cdn.testing-cylc-dfw.online/FormalDance-1.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-5.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-2.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-3.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-6.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-4.jpg",
        "https://cdn.testing-cylc-dfw.online/FormalDance-7.jpg",
    ];
    const ArtImages = [
        "https://cdn.testing-cylc-dfw.online/Art-1.jpg",
        "https://cdn.testing-cylc-dfw.online/Art-2.jpg",
    ];
    const SportsImages = [
        "https://cdn.testing-cylc-dfw.online/Sports-1.jpg",
        "https://cdn.testing-cylc-dfw.online/Sports-2.jpg",
        "https://cdn.testing-cylc-dfw.online/Sports-5.jpg",
    ];

    return (
        <Box>
            <Box
            bg={'#00cecb'}
            maxWidth={'100%'}
            height={'100%'}
            >
                <Title
                titleLineOne={titleLineOne}
                titleLineTwo={titleLineTwo}
                />
            </Box>
            <Box>
            <Box
            justifyContent={"center"}
            display={"flex"}
            paddingBottom={"7%"}
            bg={"#00cecb"}
            >
                <AutoScrollingGallery
                images={AutoImages}
                images2={AutoImages2}
                speed={30}
                categories={categories}
                />
            </Box>
            </Box>
            <Box
            pb={"5%"}
            bg={"#FFFFEA"}
            >
                <LeadershipBlock
                images={LeaderShipImages}
                categories={categories}
                />
            </Box>
            <Box
            padding={"10%"}
            bg={"#FFFFEA"}
            >
                <Box
                justifyContent={"center"}
                display={"flex"}
                >
                    <GalleryBlock
                    title={titleDance}
                    categories={categories}
                    images={DanceImages}
                    subtext={subtextDance}
                    />
                </Box>
            </Box>
            <Box
            pt={"5%"}
            pb={"5%"}
            bg={"#FFFFEA"}
            >
                <FilmBlock
                />
            </Box>
            <Box
            padding={"10%"}
            bg={"#FFFFEA"}
            >
                <Box
                justifyContent={"center"}
                display={"flex"}
                >
                    <CenterGalleryBlock
                    title={titleCooking}
                    categories={categories}
                    images={CookingImages}
                    subtext={subtextCooking}
                    />
                </Box>
            </Box>
            <Box
            pb={"5%"}
            pt={"5%"}
            bg={"#FFFFEA"}
            >
                <VerticalScrollingGallery
                images1={FormalImages1}
                images2={FormalImages2}
                categories={categories}
                />
            </Box>
            <Box
            p={"10%"}
            bg={"#FFFFEA"}
            >
                <Box
                justifyContent={"center"}
                display={"flex"}
                >
                    <GalleryBlock
                    title={titleArt}
                    categories={categories}
                    images={ArtImages}
                    subtext={subtextArt}
                    />
                </Box>
            </Box>
            <Box
            pt={"5%"}
            pb={"10%"}
            bg={"#FFFFEA"}
            >
                <SportsBlock
                images={SportsImages}
                categories={categories}
                />
            </Box>
        </Box>
    )
}

export default Details;