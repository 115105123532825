import React, { useState, useEffect } from "react";
import { Box, Text, VStack, Image, Heading } from "@chakra-ui/react";
import "./fonts/fonts.css";

const CenterGalleryBlock = ({ title, subtext, images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const getPositionStyles = (index) => {
    let positionOffset = index - currentIndex;

    if (positionOffset < 0) {
      positionOffset += images.length;
    }

    if (positionOffset === 0) {
      // Center image - Highlighted
      return {
        zIndex: 3,
        transform: "scale(1.1)",
        left: {base:"22.5%", lg:"25%","2xl":"25%"},
        opacity: 1,
      };
    } else if (positionOffset === 1 || positionOffset === images.length - 1) {
      // Side images - Partially visible and slightly shifted
      return {
        zIndex: 2,
        transform: "scale(0.9)",
        left: positionOffset === 1 ? {base:"37.5%",lg:"40%","2xl":"40%"} : {base:"7.5%", lg:"10%","2xl":"10%"}, // Left and right of center
        opacity: 0.5,
      };
    } else {
      // Hidden or out-of-view images
      return {
        zIndex: 1,
        transform: "scale(0.8)",
        opacity: 0,
      };
    }
  };

  return (
    <Box
      bg={"#E5A4CB"}
      borderRadius={{ base: "10px", lg: "20px" }}
      width={"97%"}
      height={"100%"}
      overflow={"hidden"}
      position={"relative"}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <VStack
        align={"center"}
        maxWidth={"45%"}
        marginLeft={"3%"}
        pt={"3%"}
        pb={"30%"}
      >
        {subtext.map((subtext) => (
          <Text
            key={subtext}
            fontFamily={"NohemiLight"}
            fontSize={{ base: "3vw", lg: "1.5vw", "2xl": "1.25vw" }}
          >
            {subtext}
          </Text>
        ))}
        {title.map((title) => (
          <Heading
            key={title}
            fontSize={{ base: "6vw", lg: "4.5vw", "2xl": "4vw" }}
            fontFamily={"MeshedDisplay"}
            textAlign={"center"}
          >
            {title}
          </Heading>
        ))}
        <Box
          height={"50%"}
          p={"20%"}
        >
          {images.map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`Gallery image ${index + 1}`}
              objectFit={"cover"}
              pos="absolute"
              transform="translate(-50%, -50%)"
              boxSize={{base:"55%", lg:"50%", "2xl":"50%"}}
              borderRadius={{base: "10px", lg: "20px"}}
              transition="all 1s ease"
              loading="lazy"
              {...getPositionStyles(index)}
            />
          ))}
        </Box>
      </VStack>
    </Box>
  );
};

export default CenterGalleryBlock;
