import React from 'react';
import { Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, OrderedList, ListItem, UnorderedList } from '@chakra-ui/react';

const ApplicationFAQ = () => {
    return (
        <Box bg="FFFFEA" p={"5%"} maxWidth="90%" mx="auto">
                    <Heading fontSize="3vw" textAlign="center" mb={"4%"}>
                        Frequently Asked Application Questions
                    </Heading>
                    <Accordion
                    allowToggle
                    fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}
                    >
                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Why does CYLC have a Two-step Application Process?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                In the past years of 2018 & 2019, CYLC received more than 1300 applications during Step One 
                                online application and accepted the first 400 based on their timestamp, who were then notified 
                                to submit the Step Two Acceptance Package. To save all parties’ time and effort, CYLC has 
                                adopted this two-step process to timestamp all applications and ensure that all applicants 
                                have a fair opportunity to apply and attend.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        How do I apply using this two-step process?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Please refer to the CYLC Two-Step Application Process Instructions.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Which website should I go to for the registration or would the link be distributed through email?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Please check on www.cylc-dfw.org and go to the Application tab to find the information.  
                                You may also refer to CYLC’s Facebook page to obtain the updated information.  
                                CYLC will send an email reminder for the application process, but it is your 
                                responsibility to check the website regularly for details or updates as changes 
                                may occur during the process.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Is the online application reviewed on a first-come-first-serve basis?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, the online application is sorted using the timestamp on the final confirmation page and 
                                reviewed on a “first-come-first-serve” basis. The application for most grade levels was filled 
                                in less than 1 minute 40 seconds in past years.  It is important to have all necessary information
                                 (Camper personal information, parents’ contact, shirt size, roommate, past camp years…) in hand
                                  to complete the application.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        When will the applications be available online?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Starts 1/22 Monday, ends 1/28 Sunday
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        May I send in the Acceptance Package directly without first completing Step 1?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                No, Acceptance Packages/Step 2s directly submitted without first completing the Step 1 
                                process and receiving an acceptance email with further instructions will NOT be accepted 
                                and will not constitute a valid application.  Please wait until you receive the email 
                                acceptance notification and then submit the paper Acceptance Package.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        How soon should I turn in my Acceptance Package?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Please wait until you receive the acceptance confirmation email from CYLC.  Then complete the 
                                Acceptance Package by following the instructions of step two, described in the instructions section 
                                of the application process.  Complete the Google Form Step 2 Acceptance Package and mail the camp 
                                fee check of $710 to CYLC, P.O. Box 868156, Plano, TX 75086, postmarked on or before February 9, 2024.  
                                Please double-check that the Acceptance Package is completed before mailing, as an incomplete Acceptance 
                                Package may be subject to disqualification.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Is the applicant "IN" when I complete the online application?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                No, completing the online application is only the first step. 
                                 Once you receive an email notice that your application has been ACCEPTED, 
                                 please submit the Step 2 Acceptance Package with the required documents as instructed
                                 and postmarked on or before February 9, 2024.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Do I need to do the online application if I am applying for a counselor?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, both camper and counselor applicants need to apply online as the first step. In step two, 
                                you need to complete camper forms as instructed.  You may apply online for counselor and assistant 
                                counselor after completing the camper application. We have limited spots; there is no privilege 
                                for past counselors in the application.  Please follow the process carefully as instructed. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Do I get a confirmation after completing the online application?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                You will get a copy of your Google form responses in your email at the end of the online 
                                application (for both Step 1 and Step 2).  Please save that email copy of your responses 
                                for your record.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        I messed up my application; can I make a change without affecting whether or not I get in?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                It is your responsibility to follow the application process and carefully check all the documents 
                                before submission.  Any mistakes may adversely affect the processing of your application, especially
                                 the correct contact information such as email address and cell numbers. The uniform size will also 
                                 be based on what is recorded on the application.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        How quickly do spots run out?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                It depends on how many applicants apply for each age team.  In 2018 and 2019, 
                                the teams were filled on average in 1 minute 20 seconds. The times ranged from 1 min to 1 
                                min 45 sec.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        How many campers will CYLC take? How many groups and what is the group size?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                CYLC will take 400 applicants, including assistant counselors and counselors.  
                                There are anywhere between eight to ten teams, and each team has on average 25-35 campers 
                                (balanced by gender).
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Is there a place I can specify who I want to room with during camp?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, please specify who you want to room with during camp.  CYLC will assign a roommate for 
                                you if your roommate did not get in or you did not specify a roommate on the application form. 
                                You may specify who you do not want to room with as well, but there is no guarantee either way.  
                                We will do our best to accommodate all requests.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Will everyone get a confirmation email?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, CYLC will email the acceptance notice and you may follow instructions in that email 
                                acceptance to complete the Step 2 Acceptance Package online Google Form and mail in the check.  
                                You will also receive an email notice if the team is full and you are put on the applicant waitlist.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Can I pay the camp fee online? For example by Paypal or credit card? What method of payment will be accepted for the camp?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                No, the online application is the first step, and you don’t pay the camp fee until 
                                you submit your Step 2 Acceptance Package (if you are accepted). After you receive the 
                                acceptance notice, then you should include the camp fee through mail and submit a picture of 
                                the check in your Step 2.  Personal checks and money orders are accepted for the camp.  Any 
                                returned check penalty will be paid by the applicant. No credit card/PayPal will be accepted.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Shall I register by age group or by grade level?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Please follow the grade level and sign up on the assigned date.  CYLC reserves the right 
                                to verify an applicant’s birthday or school grade level, as any “incorrect” birthday or grade 
                                level may cause disqualification.  CYLC may request the birth certificate or school report card 
                                as a reference.  
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        May I register the camper after the application week?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, you may still sign the camper up after the application week.  Online Application 
                                will remain open until February 9, 2024 or close early by announcement. Your information 
                                will still be time stamped after completing Step 1
                            </AccordionPanel>
                        </AccordionItem>

                        <Heading fontSize={"3vw"} textAlign={"center"} mt={"4%"} mb={"4%"}>
                            Frequently Asked General Questions
                        </Heading>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        What does CYLC stand for?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                CYLC stands for “Community Youth Leadership Camp”.  The camp is held once a year and is usually
                                 on the 3rd or 4th week of July.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        What's the age limit for the camp?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Currently, CYLC accepts 4th grade (age 10) to 12th grade students. Different grades
                                 may have more than one team based on age and number of applicants.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Does my child have to find his/her own roommate or will you pair them up?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                            CYLC will assign the roommate based on the following:
                            <OrderedList>
                                <ListItem>
                                    Whether both campers list each other.
                                </ListItem>
                                <ListItem>
                                    Whether one camper lists another.
                                </ListItem>
                                <ListItem>
                                    If no roommate request is submitted, then CYLC will assign the roommate for that camper.
                                </ListItem>
                            </OrderedList>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        What's the dorm arrangement for the camp?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Each dorm room has two beds, a shared living space, and a private bath.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        If I want to withdraw from the camp due to some emergency reason after I 
                                        registered and paid in full, can I get full refund or partial refund and how 
                                        much I can get? Is there a refund if my kid can't attend?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                There is a $50 nonrefundable application fee.  You will get the refund with a $50 
                                registration fee deduction before March 31, 2024.  No refund after April 1, 2024. Please
                                 submit a written request to withdraw from camp. Once you withdraw from camp, you will 
                                 lose your spot in the camp and can’t request to get the spot back. Applicants cannot request 
                                 a personal replacement if the applicant cannot attend the camp. No camp fees can be rolled over 
                                 for next year. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        May I pay the camp fee in installments?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                No, the camp fee must be paid in full by check with the Acceptance Package.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        If my child has to leave before the end of camp, will I still have to pay the full amount?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, the full amount of camp fee should be submitted with the Acceptance Package. The camp 
                                fee is not refundable after April 1, 2024 (see question 5 for more details).  Please plan 
                                your schedule accordingly to allow your camper to attend for the whole duration. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        If my child has an important event during the camp, can they still attend the camp and miss one day of camp? Once my child leaves camp for another obligation, can he/she return to camp?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, the camper may still attend the camp and miss one day.  Please send in written notice 
                                to the team teacher and counselor before camp starts.  It is the parent’s responsibility to 
                                organize transportation to and from camp. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        If my child needs to take medicine every day, do you have a nurse to help him/her about this?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, we have a nurse on site to take care of all medical situations.  All prescription 
                                medications must be given to team teachers/counselors on the first day of check in.  
                                Team teachers are responsible for ensuring that the camper takes the medicine on time. 
                                The camp site also has a medical center for general medical needs.  CYLC will notify parents 
                                immediately of any medical emergencies should the situation arise.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Is the camp overnight? Any requirements for a kid to attend the camp?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, CYLC is an overnight camp on a college campus. It is open to all children who 
                                meet the age requirements.  The camp requires a lot of physical activities and team work; 
                                any misconduct may lead to dismissal from the camp without refund. CYLC will send out a parent’s 
                                letter in July with details of things to bring, check in/out process and the drop off location 
                                for buses.  
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        How many teachers/TAs/Counselors are in the camp?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                CYLC has a dedicated teacher and a counselor team of 5-6 C/ACs for each team. 
                                Teachers/TAs/Counselors total about 85 people at camp.  CYLC also has around 10 
                                extra parents and volunteers to help on the first and last days of camp.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        What are the activities in the camp?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                The main activities include Leadership classes/workshops, dance/performing arts, arts & crafts, 
                                cooking, filming, performing arts, etiquette, self-defense, rock-climbing, team building, and team 
                                project and leadership themed programs.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        How safe is the camp?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                The teams are always escorted by teachers and counselors and are under their supervision 
                                for every class.  The dorm is dedicated for CYLC use only and the entrance cards are only 
                                held by CYLC staff and counselors. On-duty teachers also walk around the floors to make sure 
                                campers are sleeping after curfew. Each floor has teachers, TAs, and C/ACs to ensure that campers’ 
                                needs are promptly met and addressed. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        I am not in the same Ban/team as my friends. How are the Bans/teams split?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                In most cases, the same grade level should be in the same team, but some of the grade levels 
                                may have more applicants than others, which may split them into different teams.  CYLC cannot
                                 differentiate who is friends with whom and is not capable of meeting each camper’s needs/requests. 
                                  CYLC encourages campers to meet new friends and learn new things. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Do I still have to go to Formal if I don't bring formal clothes?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Everyone in the camp is required to follow the scheduled events. CYLC will 
                                ask the parents to deliver formal clothes to camp before the formal night on Tuesday,
                                 July 18, if the student did not bring formal clothes. It is important for parents to 
                                 read the parent’s letter and make sure all items are prepared before camp.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        What should I bring to the camp? What's the drop off and pick up procedure of the camp?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Please refer to the parent’s letter in July for all details of things to bring and 
                                drop off/pick up schedule. You may call the teachers/counselors with questions then. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        When is the camp reunion?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                The camp reunion usually is scheduled on the weekend 1-2 weeks after the camp.  
                                We will send out an email to each camper for the date of the reunion. 
                            </AccordionPanel>
                        </AccordionItem>

                        <Heading fontSize="3vw" textAlign="center" mb={"4%"} mt={"4%"}>
                            Counselor/Assistant Counselor Questions
                        </Heading>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Do I need to turn in the camper application in order to apply to be a counselor?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, please submit the online application first and mark that you are also applying for
                                 counselor.  Once you receive the acceptance email, then submit the Acceptance Package. 
                                 The online counselor Google Form application link will be emailed at the same time as 
                                 the acceptance email.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        How long does my essay need to be?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                The essay is 250-650 words. Longer is not better as long as you adequately address the prompts. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        How long do my answers to the questions need to be?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Answers should be concise and clear.  We are looking for creative ideas and practical solutions. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        How can I submit my counselor application package?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                We have set up a Google Form for you to submit your question answers, resume, essay, 
                                and code of conduct.  We will email the link to you directly after you are accepted through Step 1 . 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        Are grades/rank a major factor in selecting C/ACs? What factors are considered?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                No, CYLC provides equal opportunities for all candidates. The school ranking/grades
                                 are not the major factors for selection.  CYLC is looking for the leadership traits of 
                                 creativity, confidence, integrity, positive attitude, and behavior.  Most important is 
                                 being a good team player and a good role model for campers.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        If I don’t make C/AC will I be a camper?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, you will keep your spot as a camper.  The ratio of C/AC selection rate 
                                is historically about 50%, but this is a great opportunity for you to prepare 
                                yourself early for college applications and the interview process. Every step of the C/AC 
                                application process is important.  CYLC hopes this experience can help you build up confidence
                                 and also polish other leadership skills.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        I have never been to CYLC camp before; can I still apply to be a counselor?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Yes, you have the same opportunities as other candidates who have attended CYLC before. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        If I have an unscheduled emergency and I miss more trainings than allowed, will I be disqualified?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                CYLC counselor training is very important.  Please arrange your schedule accordingly so as 
                                not to conflict with the counselor training schedule especially for new assistant counselors.  
                                Missing any session will make it hard for you and your team to evenly divide the workload and 
                                will be unfair to other counselors.  As such, all training sessions are mandatory, but 
                                reasonable excused absences will be considered on a case-by-case basis. With the exception of 
                                illness and emergencies, all excused absences must be disclosed in advance and preapproved by 
                                the CYLC committee. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        I have absolutely no talent for dancing. How will this affect my candidacy?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                CYLC is not a dance camp but dance is an important way to present your teamwork skills.  
                                Your actual skill level will not affect your counselor selection, but it is important to keep a 
                                positive attitude as that will be considered more heavily during the review process.  
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        How can I improve my application?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                Be yourself.  CYLC does not have any cookie-cutter requirements in its counselor candidates.  
                                Each candidate will be considered individually and evaluated holistically based on his or her 
                                individual strengths and weaknesses.  Pay attention to details and make your application look 
                                professional (e.g., make sure your application, resume, essay and picture are nice and clean). 
                                Each interview is six minutes long.  Let the teachers get to know you, and you may always seek 
                                more practice by asking your parents for tips or speaking with past counselors for additional 
                                advice prior to the interview.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <Heading>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                        What is the counselor training schedule for 2024?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Heading>
                            <AccordionPanel pb={4}>
                                <UnorderedList>
                                    <ListItem>
                                        The Counselor application due date is 2/20/2024. You will be notified of your interview 
                                        time by 2/23/2024. If you can not make your scheduled time, you must arrange yourself 
                                        to switch with another candidate and notify CYLC Admin Team by 2/25/2024.
                                    </ListItem>
                                    <ListItem>
                                        Counselor interview:  3/2 Saturday and 3/3 Sunday.
                                    </ListItem>
                                    <ListItem>
                                        The first Meet and Greet is 3/23 Saturday
                                    </ListItem>
                                    <ListItem>
                                        Counselor Training dates are 6/22-6/23, 6/29-6/30, 7/13-7/14
                                    </ListItem>
                                    <ListItem>
                                        Move in date is 7/21 Sunday.
                                    </ListItem>
                                </UnorderedList>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Box>
    );
};

export default ApplicationFAQ;