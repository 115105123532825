import React, { useEffect, useRef } from 'react';
import { Box, Image, Text, HStack } from '@chakra-ui/react';

const AutoScrollingGallery = ({ images, images2, speed = 40, categories }) => {
  const galleryRef1 = useRef(null);
  const galleryRef2 = useRef(null);

  useEffect(() => {
    const gallery1 = galleryRef1.current;
    const gallery2 = galleryRef2.current;

    if (gallery1 && gallery2) {
      let scrollPosition1 = 0;
      let scrollPosition2 = 0;

      function step() {
        scrollPosition1 += speed / 50;
        scrollPosition2 += speed / 50;

        if (scrollPosition1 >= gallery1.scrollWidth / 2) {
          scrollPosition1 = 0; // Reset position for seamless scrolling
        }

        if (scrollPosition2 >= gallery2.scrollWidth / 2) {
          scrollPosition2 = 0; // Reset position for seamless scrolling
        }

        gallery1.scrollLeft = scrollPosition1;
        gallery2.scrollLeft = gallery2.scrollWidth / 2 - scrollPosition2;

        requestAnimationFrame(step);
      }

      requestAnimationFrame(step);
    }
  }, [speed]);

  return (
    <Box
      bg="#FFFFEA"
      borderRadius={{ base: "10px", lg: "20px" }}
      width={{ base: "90%", lg: "94%", "2xl": "90%" }}
      height={{ base: "40%", lg: "80%", "2xl": "90%" }}
      overflow="hidden"
      position="relative"
      py="3%" // Consolidated padding-top and padding-bottom
    >

      <HStack
        justify="center"
        align="center"
        spacing="4.5%"
        p="3%"
        pt={"1%"}
        whiteSpace="nowrap"
        fontSize={{ base: "2.3vw", lg: "2.1vw", "2xl": "2vw" }}
      >
        {categories.map((category) => (
          <Text key={category} color="black" opacity="90%" fontFamily="NohemiLight">
            {category}
          </Text>
        ))}
      </HStack>

      {/* First Gallery */}
      <Box ref={galleryRef1} display="flex" overflowX="hidden" whiteSpace="nowrap">
        <HStack>
          {images.concat(images).map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              height={{ base: "20vh", lg: "45vh" }}
              width={{ base: "50vw", lg: "45vw" }}
              borderRadius={{ base: "10px", lg: "20px" }}
              objectFit="cover"
              mr="2%"
              flexShrink={0}
              loading="lazy"
            />
          ))}
        </HStack>
      </Box>

      {/* Second Gallery */}
      <Box ref={galleryRef2} display="flex" overflowX="hidden" whiteSpace="nowrap" pt="3%">
        <HStack>
          {images2.concat(images2).map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              height={{ base: "20vh", lg: "45vh" }}
              width={{ base: "50vw", lg: "45vw" }}
              borderRadius={{ base: "10px", lg: "20px" }}
              objectFit="cover"
              mr="2%"
              flexShrink={0}
              loading="lazy"
            />
          ))}
        </HStack>
      </Box>
    </Box>
  );
};

export default AutoScrollingGallery;
